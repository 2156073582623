<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('update:activated', false)"
    @keydown="$emit('update:activated', false)"
    max-width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">Atributos Coringa:</v-card-title>
      <v-card-text>
        Caso esteja <i>desabilitado</i>, a conciliação é processada com
        comportamento padrão, ou seja, irá conciliar apenas se os atributos na
        base de Partida e de Referência forem identicos.
      </v-card-text>
      <v-card-text>
        Caso esteja <i>habilitado</i>, a conciliação irá conciliar atributos
        vazios ou invalidos com qualquer valor.
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="$emit('update:activated', false)"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    activated: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    dialog() {
      return this.activated;
    }
  }
};
</script>
